<template>
    <CoreUiForm
        v-slot="{ formData, isFormSubmitting }"
        :schema="schema"
        :on-submit="handleNewsletterFormSubmit"
        class="w-full"
        notify-on-error="all"
    >
        <BaseUiElementGroup>
            <BaseUiInput
                v-model:form="formData.email"
                color="white"
                :placeholder="$t('labels.your_email')"
                type="email"
                @input="sentSuccessfully = false"
            >
                <template #leading>
                    <IconMail class="ml-2" width="24" height="24" />
                </template>
            </BaseUiInput>

            <BaseUiButton
                :color="!sentSuccessfully ? 'purple' : 'success'"
                size="ultra"
                type="submit"
                :disabled="sentSuccessfully ? 'silently' : false"
                :loading="isFormSubmitting"
            >
                <template v-if="!sentSuccessfully">
                    {{ $t('sections.newsletter.subscribe') }}
                </template>

                <IconCheckmark v-else height="21" color="white" aria-hidden />
            </BaseUiButton>
        </BaseUiElementGroup>
    </CoreUiForm>
</template>

<script lang="ts" setup>

import { NewsletterType } from '@sim-api-enums/newsletter'
import { z } from 'zod'
import { EcoMailTag } from '../assets/ts/enums/eco-mail'

const { t } = useI18n()
const { notifySuccess } = useNotifications()

const sentSuccessfully = ref<boolean>(false)
const ecoMail = useEcoMail()
const newslettersService = useNewslettersApiService()

async function handleNewsletterFormSubmit(payload: schema) {
    sentSuccessfully.value = false

    await newslettersService.post({
        email: payload.email,
        type: payload.type,
        gender_oriented_product_preference: null,
    })

    // purposely not awaiting
    ecoMail.subscribe(EcoMailTag.NEWSLETTER, payload.email)

    sentSuccessfully.value = true

    notifySuccess(t('_core_theme.notifications.newsletter_success'))
}

const schema = z.object({
    email: zStringMailType,
    type: z.number().default(NewsletterType.GENERAL),
    /* gender_oriented_product_preference: z.number().nullable().default(null), */ // TODO
})

export type schema = z.infer<typeof schema>

</script>

<style lang="scss" scoped>

:deep(.sim-newsletter-subscribe__input) {
    height: 100%;
    padding: 0 1.25rem;

    @include sim-text-small;
}
</style>
