export const ScssBreakpoints = {
    xs: 350,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1536,
} as const

export type ScssBreakpoint = keyof typeof ScssBreakpoints

export type DefaultColors = 'basic' | 'primary' | 'success' | 'warning' | 'danger'
