<script lang="tsx">
import type { SlotsType } from 'vue'
import {
    type BaseUiElementGroupProvide,
    type BaseUiElementPositionInGroup,
    SymbolBaseUiElementGroup
} from '@core/app/composables/components'
import { ScssBreakpoints } from '@core/types/scss'

export type BaseUiElementGroupProps<T> = {}

type BaseUiElementGroupSlots<T> = {
    default: {}
}

type ComponentOptions = {}

export function defineComponentBaseUiElementGroup<T>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiElementGroupProps<T>, BaseUiElementGroupSlots<T>>) {
    return defineComponent(
        (props: BaseUiElementGroupProps<T>, ctx) => {
            function renderDefaultSlot(slotData: BaseUiElementGroupSlots<T>['default']) {
                const slotFunction = options?.slots?.default ?? ctx.slots.default
                const children = slotFunction?.(slotData)
                const normalizedChildren = Array.isArray(children) ? children : children ? [children] : []

                const hasMultipleChildren = normalizedChildren.length > 1
                if (!hasMultipleChildren) return normalizedChildren

                const breakpointKeys = Object.keys(ScssBreakpoints)

                const parsedChildren = normalizedChildren.map(child => {
                    const childClass: string = child.props?.['class'] ?? ''

                    const visibilityClass = childClass.split(' ').find(className => {
                        return breakpointKeys.some(breakpointKey => className.startsWith(`${breakpointKey}:`) && className.endsWith('hidden'))
                    })

                    if (!visibilityClass) {
                        return {
                            hiddenAfter: null,
                            node: child,
                        }
                    }

                    const classParts = visibilityClass.split(':')
                    const breakpoint = classParts[0] as ScssBreakpoint
                    const visibility = classParts[1]

                    if (visibility !== 'hidden') {
                        return {
                            hiddenAfter: null,
                            node: child,
                        }
                    }

                    return {
                        hiddenAfter: breakpoint,
                        node: child,
                    }
                })

                const roundedResetR: Record<ScssBreakpoint, string> = {
                    xs: 'max-xs:!rounded-r-none',
                    sm: 'max-sm:!rounded-r-none',
                    md: 'max-md:!rounded-r-none',
                    lg: 'max-lg:!rounded-r-none',
                    xl: 'max-xl:!rounded-r-none',
                    xxl: 'max-xxl:!rounded-r-none',
                }

                const roundedResetL: Record<ScssBreakpoint, string> = {
                    xs: 'max-xs:!rounded-l-none',
                    sm: 'max-sm:!rounded-l-none',
                    md: 'max-md:!rounded-l-none',
                    lg: 'max-lg:!rounded-l-none',
                    xl: 'max-xl:!rounded-l-none',
                    xxl: 'max-xxl:!rounded-l-none',
                }

                const elements = parsedChildren.map((entry, index) => {
                    const classes: string[] = []

                    const nextHiddenBiggestBreakpoint = parsedChildren.slice(index + 1).reduce<ScssBreakpoint | null>((acc, child) => {
                        if (!child.hiddenAfter) return acc
                        if (!acc) return child.hiddenAfter
                        return breakpointKeys.indexOf(child.hiddenAfter) > breakpointKeys.indexOf(acc) ? child.hiddenAfter : acc
                    }, null)

                    const previousHiddenBiggestBreakpoint = parsedChildren.slice(0, index).reverse().reduce<ScssBreakpoint | null>((acc, child) => {
                        if (!child.hiddenAfter) return acc
                        if (!acc) return child.hiddenAfter
                        return breakpointKeys.indexOf(child.hiddenAfter) > breakpointKeys.indexOf(acc) ? child.hiddenAfter : acc
                    }, null)

                    if (index === 0) {
                        // first element
                        if (nextHiddenBiggestBreakpoint) {
                            classes.push(roundedResetR[nextHiddenBiggestBreakpoint])
                        } else {
                            classes.push('!rounded-r-none')
                        }
                    } else if (index === parsedChildren.length - 1) {
                        // last element
                        if (previousHiddenBiggestBreakpoint) {
                            classes.push(roundedResetL[previousHiddenBiggestBreakpoint])
                        } else {
                            classes.push('!rounded-l-none')
                        }
                    } else {
                        // center element
                        if (previousHiddenBiggestBreakpoint) {
                            classes.push(roundedResetL[previousHiddenBiggestBreakpoint])
                        } else {
                            classes.push('!rounded-l-none')
                        }

                        if (nextHiddenBiggestBreakpoint) {
                            classes.push(roundedResetR[nextHiddenBiggestBreakpoint])
                        } else {
                            classes.push('!rounded-r-none')
                        }
                    }

                    return {
                        classes: classes.join(' '),
                        node: entry.node,
                    }
                })

                return elements.map((entry, index) =>
                    <BaseUiElementGroupProvider
                        position={index === 0 ? 'leading' : index === normalizedChildren.length - 1 ? 'trailing' : 'center'}
                        classes={entry.classes}
                    >
                        {entry.node}
                    </BaseUiElementGroupProvider>
                )
            }

            return () => (
                <div class="sim-el-group">
                    {renderDefaultSlot({})}
                </div>
            )
        },
        {
            props: {},
            slots: Object as SlotsType<BaseUiElementGroupSlots<T>>,
            emits: {},
        }
    )
}

interface BaseUiElementGroupProviderProps {
    /**
     * @deprecated Do not use for changing the appearance of the element.
     * The position doesn't take css visibility into account.
     */
    position: BaseUiElementPositionInGroup
    classes?: string | null
}

const BaseUiElementGroupProvider = defineComponent(
    (props: BaseUiElementGroupProviderProps, ctx) => {

        provide<BaseUiElementGroupProvide>(SymbolBaseUiElementGroup, {
            position: () => props.position,
            classes: () => props.classes ?? null,
        })

        return () => ctx.slots.default?.({})
    },
    // eslint-disable-next-line vue/one-component-per-file
    {
        props: {
            position: {
                type: String as PropType<BaseUiElementGroupProviderProps['position']>,
                required: true,
            },
            classes: {
                type: String as PropType<BaseUiElementGroupProviderProps['classes']>,
                default: undefined,
                required: false,
            },
        },
        slots: {} as SlotsType<{ default: {} }>,
        emits: {},
    }
)

export default defineComponentBaseUiElementGroup()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiElementGroup.scss" as *;

</style>
